/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import categoryInit from './modules/kacheln'
import elementsize from './modules/elementsize'
import initModal from './modules/modal'
/* import initToggle from './modules/toggle-kurs'*/
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import Swiper from 'swiper'
import initHeaderShrink from './modules/header_shrink.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  elementsize()
  initToggleSubMenu()
  categoryInit()
  initModal()
  initHeaderShrink()
  formSubmit()
  initMaps()

  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  // eslint-disable-next-line
  new Swiper('#slider', {
    effect: 'fade',
    fade: {
      crossFade: false
    },
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: false
  })

  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    spaceBetween: 10,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  })
  baguetteBox.run('.gallery')
// eslint-disable-next-line

  // eslint-disable-next-line
  new Swiper('.swiper-container.sponsor-slider', {
    slidesPerView: 1,
    autoplay: 6000,
    speed: 600,
    loop: false
  })
})
