export default function initHeaderShrink () {
  let scrollTopLimit = 500
  let logoImg = document.querySelectorAll('.header-logo')
  document.addEventListener('scroll', function () {
    if (document.documentElement.scrollTop > scrollTopLimit) {
      if (typeof logoImg[0] !== 'undefined') {
        logoImg[0].classList.add('shrink')
        logoImg[0].classList.remove('normal')
      }
    }
    if (document.documentElement.scrollTop < scrollTopLimit) {
      if (typeof logoImg[0] !== 'undefined') {
        logoImg[0].classList.add('normal')
        logoImg[0].classList.remove('shrink')
      }
    }
  })
}
